import React, { useState, useEffect } from 'react'
import LogoDrak2x from '../../images/logo-dark2x.png'
import LogoLight2x from '../../images/logo2x.png'
import Menu from '../../layout/menu/Menu'
import MobileMenu from '../../layout/menu/MobileMenu'
import {Logo} from "../../components/logo/Logo"
import { Col, Container, Row } from "reactstrap"
import {Header, HeaderContent, HeaderMain, HeaderWrap} from "../../layout/header/Header"
import {Link} from "../../components/button/Button"
import { HeaderCaption, HeaderTitle } from '../../components/headerCaption/HeaderCaption'
import {BannerFourData} from './BannerData'
import JS from '../../images/icon/libs/javascript.png'
import SASS from '../../images/icon/libs/sass.png'
import GULP from '../../images/icon/libs/gulp.png'
import BS from '../../images/icon/libs/bootstrap.png'
import HTML from '../../images/icon/libs/html5.png'
import CSS from '../../images/icon/libs/css3.png'

const BannerFour = (props) =>{
    const [toggle, setToggle] = useState(false);
    const [offset, setOffset] = useState(0);
    const [mobileView, setMobileView] = useState(false);

    useEffect(() => {
        window.onscroll = () => {
        setOffset(window.pageYOffset)
        }
        window.scrollTo(0,0);
        viewChange();
        window.addEventListener("resize", viewChange);
        return () => {
            window.removeEventListener('resize', viewChange);
        };
    }, []);

    // function to change the design view under 1200 px
    const viewChange = () => {
        if (window.innerWidth < 992) {
        setMobileView(true);
        } else {
        setMobileView(false);
        }
    };
    
    
    return(
        <Header className={props.className && props.className} id={props.id && props.id} style={{ width: '100%', height: '100%' }}>
            <HeaderMain className={`header-main header-main-s1 is-sticky is-transparent on-dark ${offset > 0 ? 'has-fixed' : ''}`} style={{ width: '100%', height: '100%' }}>
                <Container className='header-container'>
                    <HeaderWrap>
                        <div className="header-logo">
                            <Logo to="/IndexFour"
                            dark ={LogoDrak2x}
                            light ={LogoLight2x}
                            />
                        </div>
                    </HeaderWrap>
                </Container>
            </HeaderMain>
            <HeaderContent style={{ width: '100%', height: '100%' }} className="py-6 is-dark mt-lg-n1 mt-n3">
                <Container>
                    <Row className='row justify-content-center text-center g-gs'>
                        <Col lg="6" md="7">
                            <HeaderCaption>
                                <HeaderTitle>Code Mage is Coming Soon!</HeaderTitle>
                                <p>Have an idea for the next great app, website or program? Now you can literally build it in minutes without hiring expensive programmers. All you need is to enter a description of what you want your project to do and then let Code Mage do the hard part for you.</p>
                                <p>Code Mage is a tool that allows you to create complex software projects without any coding experience. Simply describe what you want your project to do, and Code Mage takes care of the rest</p>
                            </HeaderCaption>
                        </Col>
                    </Row>
                </Container>
            </HeaderContent>
        </Header>
    )
}

export default BannerFour
