import React from 'react'
import BannerFour from '../section/banner/BannerFour'

const IndexFour = (props) => {
  return (
    <div className="nk-main" style={{ width: '100%', height: '100vh', margin: 0, padding: 0 }}>
        <BannerFour className="bg-dark" id="#home" />
    </div>
  )
}

export default IndexFour
